<!-- eslint-disable prettier/prettier -->
<template>
  <div class="hello">
   <headerHome msg="Learn From Home"></headerHome>
   <aboutAs></aboutAs>
   
   <ourBlog></ourBlog>
   <contactUS></contactUS> 
   <footerPage></footerPage>
   
  </div>
</template>

<script>
import headerHome from "@/components/headerHome.vue";
import aboutAs from "@/components/aboutAs.vue";
import ourBlog from "@/components/ourBlog.vue";
//import videoCom from "@/components/videoCom.vue";
import contactUS from "@/components/contactUS.vue";
import footerPage from "@/components/footerPage.vue";


export default {
  name: "HelloWorld",
  props: {
    
  },
  components: {
    headerHome,
    aboutAs,
    ourBlog,
   // videoCom,
    contactUS,
    footerPage,

  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
