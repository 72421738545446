<!-- eslint-disable prettier/prettier -->
<template>
  <div class="home">
    <HelloWorld/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
            return {
              topVid:0
            };
        },
  components: {
    
    HelloWorld,
   
  },
  mounted() {
    document.title="عبد الصمد للكيمياء | الرئيسية"
  
  },
 
  watch: {
   }
};
</script>
